import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'noDecimal'
})
export class NoDecimalPipe implements PipeTransform {

  transform(value: any): any {
    return value && typeof value === 'string' ? value.substring(0, value.indexOf('.')) : value;
  }

}
