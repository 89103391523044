import {CurrencyPipe, registerLocaleData} from '@angular/common';
import {Inject, Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import localeFr from '@angular/common/locales/fr';
import localeEn from '@angular/common/locales/en';

registerLocaleData(localeFr);
registerLocaleData(localeEn);
@Pipe({name: 'localizedCurrency', pure: false})
export class LocalizedCurrencyPipe implements PipeTransform {
  constructor(@Inject(TranslateService) private translateService: TranslateService) {}

  transform(value: number | string, currency: string): string {
    let locale = this.translateService.currentLang;
    if (locale === 'GB') {
      locale = 'en';
    }
    if (!['fr', 'en'].includes(locale)) {
      locale = 'fr';
    }
    const currencyPipe: CurrencyPipe = new CurrencyPipe(locale, currency);
    return currencyPipe.transform(value);
  }
}
