import {Component, EventEmitter, Output} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ButtonActionEnum, ButtonTypeEnum, IspColoursEnum} from '@i-supplier/angular-shared-module';
import {faCancel, faHandshake} from '@fortawesome/pro-duotone-svg-icons';

@Component({
  selector: 'negotiation-modal',
  templateUrl: './negotiation-modal.component.html',
  styleUrls: ['./negotiation-modal.component.scss']
})
export class NegotiationModalComponent {
  message: string;
  min: number = 0;
  max: number = 0;
  defaultAmount: number;
  negotiationLevel: number;
  currency: string;
  protected readonly IspColoursEnum = IspColoursEnum;
  protected readonly ButtonActionEnum = ButtonActionEnum;
  protected readonly ButtonTypeEnum = ButtonTypeEnum;
  protected readonly faCancel = faCancel;
  protected readonly faHandshake = faHandshake;

  @Output() actionConfirmed = new EventEmitter<boolean>();
  @Output() actionNegotiate = new EventEmitter<boolean>();
  @Output() actionCanceled = new EventEmitter<boolean>();

  // Intern variables
  negotiationForm: FormGroup;

  constructor(public modalRef: BsModalRef, private fb: FormBuilder) {
    this.negotiationForm = this.fb.group({
      amount: [null, [Validators.required, this.checkAmountDefault, this.checkAmountRange]]
    });
  }

  confirmAction(): void {
    this.actionConfirmed.emit(this.negotiationForm.get('amount').value);
    this.modalRef.hide();
  }

  negotiateAction(): void {
    this.actionNegotiate.emit(this.negotiationForm.get('amount').value);
    this.modalRef.hide();
  }

  cancelAction(): void {
    this.actionCanceled.emit(true);
    this.modalRef.hide();
  }

  private checkAmountDefault = (control: FormControl) => {
    const amount = control?.value;
    if (amount === this.defaultAmount) {
      return {amountDefault: true};
    }
    return null;
  }

  private checkAmountRange = (control: FormControl) => {
    const amount = control?.value;
    if (amount > this.max || amount < this.min) {
      return {amountRange: true};
    }
    return null;
  }

  get negotiationButtonText(): string {
    return this.negotiationLevel === 1
      ? 'global.button.yes-i-confirm'
      : 'marketplace.call-for-tenders-offers.negotiate';
  }
}
