export const regex = {
  companyName: '^[a-zA-ZÀ-ÿ0-9](?!.*[^\\w\\s]{2})[a-zA-ZÀ-ÿ0-9\\s\\W]*[a-zA-ZÀ-ÿ0-9]$',
  projectName: '^[a-zA-ZÀ-ÿ0-9]+(?:[ ]?(?:[-.\'/_:][ ]?)?[a-zA-ZÀ-ÿ0-9]+)*$',
  firstName: '^[a-zA-ZÀ-ÿ]+(?:[\' -][a-zA-ZÀ-ÿ]+)*$',
  lastName: '^[a-zA-ZÀ-ÿ]+(?:[\' -][a-zA-ZÀ-ÿ]+)*$',
  email: '^[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]{2,}\\.[a-z]{2,20}$',
  shareCapital: '^\\d+([.,]\\d+)?\\s[A-Z]{3}$',

  title: '^[a-zA-ZÀ-ÿ]+(?:[ -\'][a-zA-ZÀ-ÿ]+)*$',

  incorporationNumber: '^[A-Z0-9]*$',
  city: '^[a-zA-ZÀ-ÿ]+(?:[ \'-][a-zA-ZÀ-ÿ0-9]+)*$',
  street: '^(?:[A-Za-zÀ-ÿ0-9,\'.-]+[ ]?)*$',
  postCode: '^[A-Z0-9]*$',
  stateOrProvince: '^[a-zA-ZÀ-ÿ]+(?:[ .\',-][a-zA-ZÀ-ÿ]+)*$',
  country: '^[a-zA-ZÀ-ÿ]+(?:[ \'-][a-zA-ZÀ-ÿ]+)*$',
  twoDigitAndTwoDecimalPlaces: '^[0-9]{1,2}(\\.[0-9]{1,2})?$',
  tenDigitAndTwoDecimalPlaces: '^[0-9]{1,10}(\\.[0-9]{1,2})?$',
  tenDigitAndTwoDecimalPlacesWithRange: '^[0-9]{1,10}(\\.(25|5|75|0))?$',

  vatNumber: '^[A-Z]{2}([0-9]{11})$',
  registrationNumber: '^[0-9]*$',

  bicOrSwift: '^[A-Z]{6}([A-Z0-9]{2}|[A-Z0-9]{5})$',
  maxLengthEscapeSpace: '^(?:\\S\\s*){1,34}$',
  internationalPostalCode: '^[a-zA-ZÀ-ÿ0-9]+(?:[ .\'/-_:][a-zA-ZÀ-ÿ0-9]+)*$',
  feeSort: '^(buyer.projectName|purchaseOrderNumber|buyerCompanyName|supplierCompanyName|supplier.employeeName|category|amountExcludingTax|invoicedPrice|date|status|submissionDate)\,(asc|desc)$'
};
