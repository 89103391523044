import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'trim',
})
export class TrimPipe implements PipeTransform {

  transform(value: any): any {
    return value && typeof value === 'string' ? value.replace(/\s/g, '') : value;
  }

}
