<div class="modal-body p-5">
    <div class="icon-warning">
      <div class="icon-warning-content">!</div>
    </div>
    <ng-container>
        <form [formGroup]="negotiationForm">
            <div class="d-flex flex-column justify-content-center align-items-center">
                <h4 class="font-weight-bold mb-2">{{message}}</h4>

                <div class="form-group w-100 mt-3 mb-1 d-flex justify-content-center ">
                    <span>
                        {{'marketplace.call-for-tenders-offers.propose-new-amount'|translate}}
                    </span>
                    <div>
                      <div class="input-group-append mr-2 ml-2">
                        <input type="number" class="form-control-md" id="negotiateAmount" formControlName="amount" placeholder={{defaultAmount}}>
                        <span class="input-group-text to-neg-curr">{{currency | currencyFormat: 'symbol'}}</span>
                      </div>
                      <div class="ml-2">
                        <app-form-feedback
                          *ngIf="negotiationForm.get('amount').errors?.required && (negotiationForm.get('amount').dirty || negotiationForm.get('amount').touched)"
                          message="{{'global.feedback.required'|translate}}">
                        </app-form-feedback>
                        <app-form-feedback *ngIf="negotiationForm.errors?.amountDefault"></app-form-feedback>
                        <app-form-feedback *ngIf="negotiationForm.errors?.amountRange"></app-form-feedback>
                      </div>
                    </div>
                    <span>
                        {{'marketplace.call-for-tenders-offers.between-min-max'|translate : {min: min, max: max} }}
                    </span>
                </div>
              <div class="mt-2" *ngIf="negotiationLevel === 1 || negotiationLevel === 3">
                <isp-button
                  [type]="ButtonTypeEnum.CLASSIC"
                  [icon]="faCancel"
                  [colour]="IspColoursEnum.tertiary"
                  (trigger)="cancelAction()"
                  [text]="'global.button.cancel' | translate"
                ></isp-button>

                <isp-button
                  class="ml-2"
                  [type]="ButtonTypeEnum.CLASSIC"
                  [action]="ButtonActionEnum.SUBMIT"
                  [icon]="faHandshake"
                  [colour]="IspColoursEnum.primary"
                  [disabled]="negotiationForm.invalid"
                  (trigger)="negotiateAction()"
                  [text]="negotiationButtonText | translate"
                ></isp-button>
              </div>
            </div>
        </form>
    </ng-container>
  </div>
