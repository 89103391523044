<form [formGroup]="billingAddressForm" (ngSubmit)="submit()">
  <div class="row" *ngIf="!isLegalInfoUpdate">
    <div class="col-md-6 form-group">
      <label class="mb-1" for="address-name-input">
        {{ 'my-company.update-company.billing-address.create-billing-address.name'|translate }} <span class="text-primary">*</span>
      </label>
      <input type="text" class="form-control" id="address-name-input" formControlName="name">
      <app-feedback
        *ngIf="billingAddressForm.get('name').errors?.required && (billingAddressForm.get('name').dirty || billingAddressForm.get('name').touched)"
        message="{{'global.feedback.required'|translate}}">
      </app-feedback>
      <app-feedback
        *ngIf="billingAddressForm.get('name').errors?.pattern && (billingAddressForm.get('name').dirty || billingAddressForm.get('name').touched)"
        message="{{'global.feedback.pattern-address-name'|translate}}">
      </app-feedback>
      <app-feedback
        *ngIf="billingAddressForm.get('name').errors?.maxLength && (billingAddressForm.get('name').dirty || billingAddressForm.get('name').touched)"
        message="{{'global.feedback.length'|translate: {max: 75} }}">
      </app-feedback>
    </div>

    <div class="col-md-6 form-group">
      <label class="mb-1" for="recipient-input">{{ 'my-company.update-company.billing-address.create-billing-address.recipientName'|translate }}</label>
      <input type="text" class="form-control" id="recipient-input" formControlName="recipientName">
    </div>
  </div>

  <div class="row">
    <div class="col-md-6 form-group">
      <label class="mb-1" for="street1-input">
        {{ 'my-company.update-company.billing-address.create-billing-address.street1'|translate }} <span class="text-primary">*</span>
      </label>
      <input type="text" class="form-control" id="street1-input" formControlName="street1">
      <app-feedback
        *ngIf="billingAddressForm.get('street1').errors?.required && (billingAddressForm.get('street1').dirty || billingAddressForm.get('street1').touched)"
        message="{{'global.feedback.required'|translate}}">
      </app-feedback>
      <app-feedback
        *ngIf="billingAddressForm.get('street1').errors?.maxlength  && (billingAddressForm.get('street1').dirty || billingAddressForm.get('street1').touched)"
        message="{{'global.feedback.length'|translate: {max: 100} }}">
      </app-feedback>
    </div>

    <div class="col-md-6 form-group">
      <label class="mb-1" for="street2-input">{{ 'my-company.update-company.billing-address.create-billing-address.street2'|translate }}</label>
      <input type="text" class="form-control" id="street2-input" formControlName="street2">
    </div>
    <app-feedback
      *ngIf="billingAddressForm.get('street2').errors?.maxlength  && (billingAddressForm.get('street2').dirty || billingAddressForm.get('street2').touched)"
      message="{{'global.feedback.length'|translate: {max: 100} }}">
    </app-feedback>
  </div>

  <div class="row">
    <div class="col-md-6">
      <div class="row">
        <div class="col-lg-6 form-group">
          <label class="mb-1" for="country-input">
            {{ 'my-company.update-company.billing-address.create-billing-address.country'|translate }} <span class="text-primary">*</span>
          </label>
          <input type="text" formControlName="country" id="country-input" class="form-control"/>
          <app-feedback
            *ngIf="billingAddressForm.get('country').errors?.required && (billingAddressForm.get('country').dirty || billingAddressForm.get('country').touched)"
            message="{{'global.feedback.required'|translate}}">
          </app-feedback>
          <app-feedback
            *ngIf="billingAddressForm.get('country').errors?.pattern && (billingAddressForm.get('country').dirty || billingAddressForm.get('country').touched)"
            message="{{'global.feedback.pattern-country'|translate}}">
          </app-feedback>
        </div>

        <div class="col-lg-6 form-group">
          <label class="mb-1" for="city-input">
            {{ 'my-company.update-company.billing-address.create-billing-address.city'|translate }} <span class="text-primary">*</span>
          </label>
          <input type="text" formControlName="city" id="city-input" class="form-control"/>
          <app-feedback
            *ngIf="billingAddressForm.get('city').errors?.required && (billingAddressForm.get('city').dirty || billingAddressForm.get('city').touched)"
            message="{{'global.feedback.required'|translate}}">
          </app-feedback>
          <app-feedback
            *ngIf="billingAddressForm.get('city').errors?.maxlength  && (billingAddressForm.get('city').dirty || billingAddressForm.get('city').touched)"
            message="{{'global.feedback.length'|translate: {max: 60} }}">
          </app-feedback>
          <app-feedback
            *ngIf="billingAddressForm.get('city').errors?.pattern && (billingAddressForm.get('city').dirty || billingAddressForm.get('city').touched)"
            message="{{'global.feedback.pattern-city'|translate}}">
          </app-feedback>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="row">
        <div class="col-lg-6 form-group">
          <label class="mb-1" for="postCode-input">
            {{ 'my-company.update-company.billing-address.create-billing-address.postCode'|translate }} <span class="text-primary">*</span>
          </label>
          <input type="text" formControlName="postCode" id="postCode-input" class="form-control"/>
          <app-feedback
            *ngIf="billingAddressForm.get('postCode').errors?.required && (billingAddressForm.get('postCode').dirty || billingAddressForm.get('postCode').touched)"
            message="{{'global.feedback.required'|translate}}">
          </app-feedback>
          <app-feedback
            *ngIf="billingAddressForm.get('postCode').errors?.maxlength  && (billingAddressForm.get('postCode').dirty || billingAddressForm.get('postCode').touched)"
            message="{{'global.feedback.length'|translate: {max: 9} }}">
          </app-feedback>
          <app-feedback
            *ngIf="billingAddressForm.get('postCode').errors?.minLength  && (billingAddressForm.get('postCode').dirty || billingAddressForm.get('postCode').touched)"
            message="{{'global.feedback.minLength'|translate: {min: 3} }}">
          </app-feedback>
        </div>

        <div class="col-lg-6 form-group">
          <label class="mb-1" for="stateOrProvince-input">{{ 'my-company.update-company.billing-address.create-billing-address.stateOrProvince'|translate }}</label>
          <input type="text" class="form-control" id="stateOrProvince-input" formControlName="stateOrProvince">
          <app-feedback
            *ngIf="billingAddressForm.get('stateOrProvince').errors?.pattern  && (billingAddressForm.get('stateOrProvince').dirty || billingAddressForm.get('stateOrProvince').touched)"
            message="{{'global.feedback.pattern-state-or-province'|translate}}">
          </app-feedback>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="!isLegalInfoUpdate">
    <div class="col-sm-12">
      <div class="float-right">
        <button class="btn btn-secondary" type="button" (click)="cancelFormChanges()">
          {{'global.button.cancel'|translate}}
        </button>&nbsp;
        <button class="btn btn-success" type="submit" [disabled]="billingAddressForm.invalid">
          {{'global.button.'+(isUpdateMode ? 'update' :'add') | translate}}
        </button>
      </div>
    </div>
  </div>
</form>
