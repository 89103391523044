import {DatePipe, DOCUMENT} from '@angular/common';
import {Inject, Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'localizedDate',
  pure: false,
})
export class LocalizedDatePipe implements PipeTransform {
  constructor(private translateService: TranslateService, @Inject(DOCUMENT) private document: Document) {
  }

  transform(value: any, pattern: string = 'mediumDate'): any {
    let lang = this.translateService.currentLang;
    if (lang.toLowerCase() === 'gb') {
      lang = 'en';
    }
    if (lang.toLowerCase() === 'fr') {
      lang = document.location.hostname.includes('isupplier.ca') ? 'fr-CA' : 'fr';
    }
    const datePipe: DatePipe = new DatePipe(lang);
    return datePipe.transform(value, pattern);
  }
}
