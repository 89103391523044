import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {NgSelectModule} from '@ng-select/ng-select';
import {AvatarModule} from 'ngx-avatars';
import {AlertModule} from 'ngx-bootstrap/alert';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {ModalModule} from 'ngx-bootstrap/modal';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {ProgressbarModule} from 'ngx-bootstrap/progressbar';
import {TimepickerModule} from 'ngx-bootstrap/timepicker';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {ImageCropperComponent, ImageCropperModule} from 'ngx-img-cropper';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input-gg';
import {NgxSpinnerModule} from 'ngx-spinner';
import {ToastrModule} from 'ngx-toastr';
import {AlertComponent} from './components/alert/alert.component';
import {FormFeedbackComponent} from './components/form-feedback/form-feedback.component';
import {KpiCardComponent} from './components/kpi-card/kpi-card.component';
import {KpiDateRangePickerComponent} from './components/kpi-date-range-picker/kpi-date-range-picker.component';
import {KpiProgressBarComponent} from './components/kpi-progress-bar/kpi-progress-bar.component';
import {ListContentComponent} from './components/list-content/list-content.component';
import {ModalConfirmComponent} from './components/modal-confirm/modal-confirm.component';
import {NoContentComponent} from './components/no-content/no-content.component';
import {PageMenuComponent} from './components/page-menu/page-menu.component';
import {PageTitleComponent} from './components/page-title/page-title.component';
import {ProfilePictureComponent} from './components/profile-picture/profile-picture.component';
import {SpinnerComponent} from './components/spinner/spinner.component';
import {UserRibbonComponent} from './components/user-ribbon/user-ribbon.component';
import {AddressPipe} from './pipes/address.pipe';
import {BooleanFormatPipe} from './pipes/boolean-format.pipe';
import {CurrencyFormatPipe} from './pipes/currency-format.pipe';
import {DefaultPipe} from './pipes/default.pipe';
import {EllipsisPipe} from './pipes/ellipsis.pipe';
import {LocalizedCurrencyPipe} from './pipes/localized-currency/localized-currency.pipe';
import {LocalizedNumberPipe} from './pipes/localized-number.pipe';
import {StatusPipe} from './pipes/status.pipe';
import {MessageComponent} from './components/message/message.component';
import {MessageDashboardComponent} from './components/message-dashboard/message-dashboard.component';
import {KpiAvatarComponent} from './components/kpi-avatar/kpi-avatar.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { TrimPipe } from './pipes/trim.pipe';
import { NoDecimalPipe } from './pipes/no-decimal.pipe';
import { AmountFormatterPipe } from './pipes/amount-formatter.pipe';
import { MonthNamePipe } from './pipes/month-name.pipe';
import {ModalIdleWarningComponent} from './components/modal-idle-warning/modal-idle-warning.component';
import { CguModalComponent } from './components/cgu-modal/cgu-modal.component';
import { NegotiationModalComponent } from './components/negotiation-modal/negotiation-modal.component';
import {RoundPipe} from './pipes/round.pipe';
import {BillingAddressFormComponent} from './components/billing-address-form/billing-address-form.component';
import {LocalizedDatePipe} from './pipes/localized-date/localized-date.pipe';
import {IsupplierSharedModule} from '@i-supplier/angular-shared-module';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import { environment } from '../../assets/environments/environment';

@NgModule({
  declarations: [
    UserRibbonComponent,
    AlertComponent,
    PageTitleComponent,
    NoContentComponent,
    PageMenuComponent,
    FormFeedbackComponent,
    ProfilePictureComponent,
    StatusPipe,
    KpiProgressBarComponent,
    KpiDateRangePickerComponent,
    KpiCardComponent,
    AddressPipe,
    AddressPipe,
    CurrencyFormatPipe,
    BooleanFormatPipe,
    DefaultPipe,
    SpinnerComponent,
    EllipsisPipe,
    ModalConfirmComponent,
    ListContentComponent,
    MessageComponent,
    MessageDashboardComponent,
    KpiAvatarComponent,
    TrimPipe,
    NoDecimalPipe,
    LocalizedNumberPipe,
    MonthNamePipe,
    RoundPipe,
    AmountFormatterPipe,
    ModalIdleWarningComponent,
    CguModalComponent,
    NegotiationModalComponent,
    BillingAddressFormComponent,
    LocalizedDatePipe,
    LocalizedCurrencyPipe,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    AlertModule.forRoot(),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      timeOut: 5000,
      preventDuplicates: true,
      closeButton: true,
      progressBar: true,
      tapToDismiss: false,
      extendedTimeOut: 5000
    }),
    PaginationModule.forRoot(),
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ProgressbarModule.forRoot(),
    AvatarModule,
    NgxIntlTelInputModule,
    ImageCropperModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    NgSelectModule,
    InfiniteScrollModule,
    NgxSpinnerModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: false,
    }),
    NgxSpinnerModule,
    AvatarModule,
    IsupplierSharedModule.forRoot({
      api_root: environment.api_root
    }),
    PdfViewerModule,
    NgxSpinnerModule
  ],
  exports: [
    UserRibbonComponent,
    AlertComponent,
    PageTitleComponent,
    NoContentComponent,
    FormFeedbackComponent,
    BillingAddressFormComponent,
    ProfilePictureComponent,
    PageMenuComponent,
    SpinnerComponent,
    ReactiveFormsModule,
    FormsModule,
    ToastrModule,
    PaginationModule,
    ModalModule,
    AlertModule,
    PopoverModule,
    TooltipModule,
    BsDatepickerModule,
    EllipsisPipe,
    StatusPipe,
    NgxIntlTelInputModule,
    AvatarModule,
    ImageCropperModule,
    ImageCropperComponent,
    AddressPipe,
    KpiCardComponent,
    KpiDateRangePickerComponent,
    KpiProgressBarComponent,
    LocalizedDatePipe,
    CurrencyFormatPipe,
    BooleanFormatPipe,
    NgSelectModule,
    DefaultPipe,
    InfiniteScrollModule,
    TimepickerModule,
    ListContentComponent,
    MessageComponent,
    MessageDashboardComponent,
    KpiAvatarComponent,
    TranslateModule,
    TrimPipe,
    NoDecimalPipe,
    MonthNamePipe,
    LocalizedNumberPipe,
    RoundPipe,
    AmountFormatterPipe,
    ModalIdleWarningComponent,
    IsupplierSharedModule,
    LocalizedCurrencyPipe,
  ],
  entryComponents: [ModalConfirmComponent]
})
export class SharedModule {}
// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
