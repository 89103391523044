// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import {CountryISO} from 'ngx-intl-tel-input-gg';

export const commonProperties = {
  defaultCountry: document.location.hostname.includes('isupplier.ca') ? CountryISO.Canada : CountryISO.France,
  defaultCurrency: document.location.hostname.includes('isupplier.ca') ? '$CAD' : '€',
  token: 'access_token',
  tokenExpiration: 'access_token_expiration',
  login: 'authMgtApi/login',
  logout: 'authMgtApi/logout',
  cgu: 'authMgtApi/cgu',
  cguUser: 'authMgtApi/users/cgu',
  refreshToken: 'authMgtApi/refresh-token',
  tokenValidation: 'authMgtApi/token',
  forgetPassword: 'authMgtApi/forget-password',
  activateAccount: 'authMgtApi/activate-account',
  resetPassword: 'authMgtApi/reset-password',
  myCompany: 'enterpriseMgtApi/companies/:companyId',
  lang: 'authMgtApi/users/:employeeId/language',
  superset: 'reportMgtApi/superset',

  userEmailTest: 'authMgtApi/status',

  suppliersList: 'enterpriseMgtApi/companies',
  supplierById: 'enterpriseMgtApi/companies/:supplierId',
  legalForms: 'enterpriseMgtApi/companies/legal-forms',

  billingConfig: 'enterpriseMgtApi/billing-params',
  billingConfigById: 'enterpriseMgtApi/billing-params/:billingParamId',
  billingAddress: 'enterpriseMgtApi/companies/:companyId/addresses',
  billingAddressId: 'enterpriseMgtApi/companies/:companyId/addresses/:id',
  billingAddressDisable: 'enterpriseMgtApi/companies/:companyId/addresses/:addressId/disable',

  onCallList: 'enterpriseMgtApi/on-call-params',
  onCallById: 'enterpriseMgtApi/on-call-params/:onCallId',
  onCallActive: 'enterpriseMgtApi/on-call-params/active',

  tags: 'enterpriseMgtApi/tags',
  tagById: 'enterpriseMgtApi/tags/:tagId',

  searchFrenchCities: 'enterpriseMgtApi/countries/french-cities',

  permissions: 'enterpriseMgtApi/permissions',

  // Employee
  employeesList: 'enterpriseMgtApi/employees',
  authorities: 'authMgtApi/authorities',
  employeeById: 'enterpriseMgtApi/employees/:employeeId',
  employeeSelf: 'enterpriseMgtApi/employees/me',
  accountByEmployeeId: 'authMgtApi/users',
  employeePictureById: 'enterpriseMgtApi/employees/:employeeId/picture',
  myPicture: 'enterpriseMgtApi/employees/:employeeId/picture',
  employeeRoleById: 'authMgtApi/users/:employeeId',
  myProfile: 'enterpriseMgtApi/employees/:employeeId',
  projectManagers: 'enterpriseMgtApi/employees',

  // Request
  request: 'requestMgtApi/requests',
  requestById: 'requestMgtApi/requests/:requestId',
  requestDraft: 'requestMgtApi/requests/draft',
  requestDraftById: 'requestMgtApi/requests/clientStep/:requestId',
  requestHistoryStatus: 'requestMgtApi/requests/:requestId/history',
  statusList: 'requestMgtApi/requests/status',

  requestClientStepById: 'requestMgtApi/requests/clientStep/:requestId',
  requestSupplierStepById: 'requestMgtApi/requests/supplierStep/:requestId',

  additionalDocumentsByRequestId: 'requestMgtApi/requests/:requestId/additional-documents',

  clientByRequestId: 'requestMgtApi/requests/:requestId/clients',
  billingByRequestId: 'requestMgtApi/requests/:requestId/billings',

  supplierByRequestId: 'requestMgtApi/requests/:requestId/suppliers',
  supplierByRequestIdBySupplierId: 'requestMgtApi/requests/:requestId/suppliers/:supplierId',
  pointOfContactBySupplierId: 'requestMgtApi/supplier/:supplierId/point-of-contacts',

  requestProjects: 'requestMgtApi/projects',
  projectsOfProjectManager: 'requestMgtApi/projects/projectsOfProjectManager',
  providersOfProject: 'requestMgtApi/projects/providersOfProject',
  projectByRequestId: 'requestMgtApi/requests/:requestId/projects',
  duePayments: 'requestMgtApi/requests/:requestId/due-payments',
  duePaymentById: 'requestMgtApi/requests/:requestId/due-payments/:duePaymentId',
  projectByProjectId: 'requestMgtApi/projects/:projectId',
  projectByRequestIdByProjectId: 'requestMgtApi/requests/:requestId/projects/:projectId',

  providerByRequestId: 'requestMgtApi/requests/:requestId/providers',
  providerByRequestIdByProviderId: 'requestMgtApi/requests/:requestId/providers/:providerId',
  providerResume: 'requestMgtApi/requests/:requestId/providers/:providerId/resume',

  requestActionsById: 'requestMgtApi/requests/:requestId/actions',

  additionalDocuments: 'legalDocumentsMgtApi/additional-documents',

  // Dashboard
  totalRequests: 'requestMgtApi/kpis/totalRequests',
  requestContractProcessTime: 'requestMgtApi/kpis/request-contract-process-time',
  requestPoProcessTimeWithContract: 'requestMgtApi/kpis/request-po-process-time-with-contract',
  requestPoProcessTimeWithoutContract: 'requestMgtApi/kpis/request-po-process-time-without-contract',
  requestRepartitionByType: 'requestMgtApi/kpis/repartition-by-type',
  requestRepartitionByStatus: 'requestMgtApi/kpis/repartition-by-status',

  budgetCumulatedConsumption: 'requestMgtApi/kpis/tm-cumulated-consumption',
  budgetConsumptionByProject: 'requestMgtApi/kpis/tm-consumption-by-project',
  budgetExportConsumption: 'requestMgtApi/kpis/tm-export-consumption',
  budgetFeesConsumption: 'requestMgtApi/kpis/tm-fees-consumption',
  budgetOnCallConsumption: 'requestMgtApi/kpis/tm-on-call-consumption',
  budgetServiceAmountConsumption: 'requestMgtApi/kpis/tm-service-amount-consumption',
  budgetServiceDaysConsumption: 'requestMgtApi/kpis/tm-service-days-consumption',

  requestFixedPriceConsumption: 'requestMgtApi/kpis/fixed-price-consumption',
  requestExportFixedPriceConsumption: 'requestMgtApi/kpis/export-fixed-price-consumption',
  requestProvidersAvgDailyRate: 'requestMgtApi/kpis/providers-avg-daily-rate',
  requestServiceAmountConsumption: 'requestMgtApi/kpis/service-amount-consumption',

  // Gta
  projectList: 'projectMgtApi/projects',
  projectById: 'projectMgtApi/projects/:projectId',
  requestList: 'projectMgtApi/requests',
  providerList: 'projectMgtApi/providers',
  craEvents: 'projectMgtApi/cra',
  feeReports: 'projectMgtApi/fees-reports',
  feeDownloadAttachment: 'projectMgtApi/fees-reports/:feeReportId/attachment',
  camAttachment: 'projectMgtApi/cra/:craDetailId/cam',
  craAction: 'projectMgtApi/cra/actions',
  feeRefuse: 'projectMgtApi/fees-reports/refuse',
  feeValidate: 'projectMgtApi/fees-reports/validate',
  feeValidateAll: 'projectMgtApi/fees-reports/validate-all',
  feeRefuseAll: 'projectMgtApi/fees-reports/refuse-all',
  onCalls: 'projectMgtApi/on-calls',
  onCallsById: 'projectMgtApi/on-calls/:onCallId',
  onCallsByIdActions: 'projectMgtApi/on-calls/:onCallId/actions',

  // Marketplace
  callsForTender: 'marketplaceMgtApi/callsForTender',
  categoryList: 'marketplaceMgtApi/categories',
  callsForTenderById: 'marketplaceMgtApi/callsForTender/:callForTenderId',
  callsForTenderActions: 'marketplaceMgtApi/callsForTender/:callForTenderId/actions',
  callsForTenderAttachments: 'marketplaceMgtApi/callsForTender/:callForTenderId/attachments',
  callsForTenderOffers: 'marketplaceMgtApi/offers',
  callsForTenderOfferById: 'marketplaceMgtApi/offers/:callForTenderOfferId/attachment',
  callsForTenderOfferActions: 'marketplaceMgtApi/offers/:callForTenderOfferId/actions',
  totalCallForTenders: 'marketplaceMgtApi/kpis/call-for-tenders',
  pendingOffers: 'marketplaceMgtApi/kpis/offers',
  offersAverageResponseTime: 'marketplaceMgtApi/kpis/offers/average-response-time',
  offersResponseQuality: 'marketplaceMgtApi/kpis/offers/quality',
  monetaryDiffOnAdr: 'marketplaceMgtApi/kpis/offers/adr-diff',
  leagues: 'marketplaceMgtApi/leagues',
  leaguesById: 'marketplaceMgtApi/leagues/:leagueId',
  callsForTenderFile: 'marketplaceMgtApi/callsForTender/:callForTenderId/attachment',
  callsForTenderFiles: 'marketplaceMgtApi/callsForTender/:callForTenderId/attachments',
  downloadCFTAttachment: 'marketplaceMgtApi/callsForTender/:id/cft-attachment-download',
  updateCFTAttachment: 'marketplaceMgtApi/callsForTender/:callForTenderId/update-attachments',
  callsForTenderView: 'marketplaceMgtApi/callsForTender/view/:callForTenderId',
  updateOfferNegotiation: 'marketplaceMgtApi/offers/:callForTenderOfferId/negotiation',

  // Countries
  countries: 'static/countries.json',

  // fee
  feesList: 'activityReportMgtApi/fees',
  feeById: 'activityReportMgtApi/fees/:id',
  feeFileById: 'activityReportMgtApi/fees/:id/file',
  activityReportFeeHeaders: 'activityReportMgtApi/fees/headers',
  activityReportFeePatchHeaders: 'activityReportMgtApi/fees/patchHeaders',

  // activity-report
  activitySheet: 'activityReportMgtApi/activitysheet',
  activitySheetById: 'activityReportMgtApi/activitysheet/:id',
  activitySheetGetFile: 'activityReportMgtApi/activitysheet/:id/getfile',
  activitySheetToManageCount: 'activityReportMgtApi/kpis/timeSheetsToManage',
  activitySheetToManageGrouped: 'activityReportMgtApi/kpis/timeSheetsToManageGrouped',

  // on-call
  activityReportOnCalls: 'activityReportMgtApi/on-calls',
  activityReportOnCallById: 'activityReportMgtApi/on-calls/:id',
  activityReportOnCallHeaders: 'activityReportMgtApi/on-calls/headers',
  activityReportOnCallPatchHeaders: 'activityReportMgtApi/on-calls/patchHeaders',

  onCallsEnvelope: 'activityReportMgtApi/request-envelope?activityType=:activityType',
  onCallFileById: 'activityReportMgtApi/on-calls/:onCallId/file',

  onCallParams: 'enterpriseMgtApi/on-call-params',
  onCallParamById: 'enterpriseMgtApi/on-call-params/:id',

  // kpis
  submittedFeeCount: 'activityReportMgtApi/kpis/fee',
  submittedOnCallCount: 'activityReportMgtApi/kpis/on-calls',
  submittedActivitySheetCount: 'activityReportMgtApi/kpis/activity-sheet',

  // ActivityArea
  activityAreasByCompanyId: 'activityAreaMgtApi/api/v1/company/:companyId/activityArea',
  activityAreasByCFTyId: 'activityAreaMgtApi/api/v1/call-for-tender/:cftID/activityArea',
  activityAreasBasePath: 'activityAreaMgtApi/api/v1/activityArea',
  activityAreasMarketPlaceSearch: 'activityAreaMgtApi/api/v1/call-for-tender',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
